import React from "react"

import Layout from "@src/components/Layout"
import Row from "../components/Row"
import Column from "../components/Column"
import Splash from "../Features/components/Splash"
import DotBG from "../components/DotBG"
import getAppUrl from "@src/utils/getAppUrl"
import CalloutWithDemoForm from "../../../components/CalloutWithDemoForm"
import Button from "../../../components/Button"
import ImageRow from "../Features/components/ImageRow/ImageRow"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import TaskManagement from "@src/icons/v3/task-management.png"
import PipelineManagement from "@src/icons/v3/pipeline-management.png"
import ProjectManagement from "@src/icons/v3/project-management.png"
import * as styles from "./UseCases.module.scss"

const IndexPage = ({ location, pageContext }) => {
  const [splashImage, setSplashImage] = React.useState()
  const [sectionImage, setSectionImage] = React.useState()
  const breakpoints = useBreakpoint()
  const appUrl = getAppUrl.hook()
  const baseUrl =
    process.env.NODE_ENV === "staging"
      ? "https://staging.canonic.dev"
      : "https://canonic.dev"

  const {
    title,
    subtitle,
    illustration,
    useCase,
    sections: propSections,
    og,
  } = pageContext?.content || {}
  const sections = propSections.map(s => ({
    ...s,
    items: s.items.map(item => ({
      ...item,
      subtitle: item.description,
      image: item.icon,
      icon: () => (
        <i
          class={`fa ${item.icon?.startsWith("fa") ? item?.icon : "fa-bolt"}`}
        />
      ),
    })),
  }))
  React.useEffect(() => {
    sections[0].items.map(
      async ({ title, image }) =>
        await import(`../../../icons/generated-use-cases/${image}`).then(data =>
          setSectionImage(prevValue => ({
            ...prevValue,
            [title]: data.default,
          }))
        )
    )
    const splashImage = async () =>
      await import(`../../../icons/generated-use-cases/${illustration}`).then(
        data => setSplashImage(data.default)
      )
    splashImage()
  }, [pageContext])

  React.useEffect(() => {
    const link = document.createElement("link")
    link.id = "fontawesome-link"
    link.rel = "stylesheet"
    link.href =
      "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css"
    document.head.appendChild(link)
  }, [])

  return (
    <Layout
      title={title}
      description={subtitle}
      url="https://canonic.dev"
      location={location}
      ogImage={`${baseUrl}/og/${og}`}
      className={styles.useCases}
    >
      <Splash
        title={title}
        subtitle={subtitle}
        reverseOnMobile={true}
        illustration={
          <div className={styles.splashImageWrapper}>
            {<img className={styles.splashImageWrapper} src={splashImage} />}
          </div>
        }
        ctas={[
          <a href={appUrl} target="_blank" rel="noopener noreferrer">
            <Button isPrimary>Try for free</Button>
          </a>,
          <a href={"#get-a-demo"}>
            <Button borderless className={styles.splashCta}>
              Get a Demo
            </Button>
          </a>,
        ]}
      />
      <Row className={styles.row}>
        <section>
          <h2 className={styles.title}>{sections[0].title}</h2>
          {sections[0].items.map(({ title, subtitle, icon }, i) => (
            <ImageRow
              title={title}
              reverse={i % 2 !== 0}
              icon={() => (
                <img className="image-preview" src={sectionImage?.[title]} />
              )}
              subtitle={subtitle}
              borderless
            />
          ))}
        </section>
      </Row>

      {sections.slice(1, 2).map(({ title, items }, i) => {
        return (
          <Row>
            <h2 className={styles.title}>{title}</h2>
            <Column.Wrap numPerRow={4}>
              {items.map(({ title, description, icon }) => (
                <Column title={title} icon={icon} description={description} />
              ))}
            </Column.Wrap>
          </Row>
        )
      })}
      {sections.slice(2, 3).map(({ title, items }, i) => {
        return (
          <Row>
            <h2 className={styles.title}>{title}</h2>
            <Column.Wrap numPerRow={3}>
              {items.map(({ title, description, icon }) => (
                <Column title={title} icon={icon} description={description} />
              ))}
            </Column.Wrap>
          </Row>
        )
      })}
      <CalloutWithDemoForm originPage={useCase} id={"get-a-demo"} />
    </Layout>
  )
}

export default IndexPage
